<template>
    <v-container class="media-preview__container">
        <v-row class="media-preview__wrapper">
            <v-col
                v-for="(resource, key) in resources"
                :key="key"
                class="media-preview__media pa-2"
                cols="6"
                sm="3"
            >
                <media-preview-list-actions
                    :resource="resource"
                    :allow-edit="allowEdit"
                    :allow-delete="allowDelete"
                    @feature="onFeature"
                    @replace="onReplace"
                    @remove="onRemove"
                />

                <v-img
                    class="media-preview__media-image rounded"
                    height="100%"
                    :src="getThumbnail(resource)"
                    @click="setPreviewMedia(resource)"
                >
                    <template #placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                                indeterminate
                                color="secondary"
                            />
                        </v-row>
                    </template>
                </v-img>
            </v-col>

            <v-col v-if="canAdd" cols="6" sm="3" class="pa-2" @click="addMedia">
                <div class="media-preview__add-button">
                    <v-icon size="50px" class="mb-2">fa-plus</v-icon>
                    <span>Click to add media</span>
                </div>
            </v-col>
        </v-row>

        <media-preview-modal
            v-if="previewMedia"
            :media="previewMedia"
            @close="previewMedia = null"
        />

        <media-dialog
            v-if="canAdd || allowEdit"
            ref="MediaDialog"
            :module-title="moduleTitle"
            :index="index"
            :action="action"
            :options="options"
            :allow-stock-video-search="allowStockVideoSearch"
            :allow-user-images="allowUserImages"
            @confirm="onConfirm"
            @saved="onMediaSaved"
            @deleted="onMediaDeleted"
        />
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { ProvideReactive, Watch } from '@/utils/decorators';

import type { FormMediaResource, MediaAction, StockMedia } from '@/types/Media';

import MediaPreviewModal from '../MediaPreviewModal.vue';
import MediaDialog from '../MediaDialog.vue';
import MediaPreviewListActions from './MediaPreviewListActions.vue';

import { MediaList } from '@/mixins';

const MediaPreviewListProps = Vue.extend({
    props: {
        mediaResources: {
            type: Array as PropType<FormMediaResource[]>,
            required: true,
            default(): FormMediaResource[] {
                return [];
            }
        },
        allowAdd: {
            type: Boolean,
            default() {
                return true;
            }
        },
        allowEdit: {
            type: Boolean,
            default() {
                return true;
            }
        },
        allowDelete: {
            type: Boolean,
            default() {
                return true;
            }
        },
        options: {
            type: Object as PropType<Record<string, unknown>>,
            default() {
                return {};
            }
        },
        allowStockVideoSearch: {
            type: Boolean,
            default() {
                return false;
            }
        },
        allowUserImages: {
            type: Boolean,
            default() {
                return true;
            }
        },
        index: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        previewOnly: {
            type: Boolean,
            default() {
                return false;
            }
        },
        userId: {
            type: Number,
            default() {
                return 0;
            }
        },
        moduleTitle: {
            type: String,
            default() {
                return '';
            }
        },
        max: {
            type: [String, Number],
            default() {
                return 10;
            }
        }
    }
});

@Component({
    components: {
        MediaPreviewModal,
        MediaDialog,
        MediaPreviewListActions
    }
})
export default class MediaPreviewList extends mixins(
    MediaPreviewListProps,
    MediaList
) {
    $refs!: {
        MediaDialog: InstanceType<typeof MediaDialog>;
    };

    action: MediaAction = 'Add';

    mediaToReplace: FormMediaResource | null = null;

    previewMedia: StockMedia | null = null;

    resources: FormMediaResource[] = [...this.mediaResources];

    get canAdd() {
        return this.allowAdd && this.resources.length < Number(this.max);
    }

    @Watch('mediaResources')
    onMediaResources(mediaResources: FormMediaResource[]) {
        this.resources = [...mediaResources];
    }

    @Watch('previewMedia')
    onPreviewMediaChange(v: StockMedia) {
        if (v) {
            this.$store.dispatch('modal/open', 'previewMedia');
        }
    }

    // Props providing needs getter
    @ProvideReactive()
    get user_id() {
        return this.userId;
    }

    openMediaDialog() {
        this.$store.dispatch('modal/open', `media-dialog-${this.index}`);
    }

    closeMediaDialog() {
        this.$store.dispatch('modal/close', `media-dialog-${this.index}`);
    }

    onFeature(resource: FormMediaResource) {
        this.feature(resource);

        this.emitMedia();
    }

    onReplace(resource: FormMediaResource) {
        this.action = 'Replace';

        this.mediaToReplace = resource;

        this.openMediaDialog();
    }

    onRemove(resource: FormMediaResource) {
        this.remove(resource);

        this.emitMedia();
    }

    setPreviewMedia(resource: FormMediaResource) {
        this.previewMedia = {
            thumbnail: resource.media_file.preview_image as string,
            url: this.getFullResolution(resource),
            type: resource.media_file.type,
            source: null,
            stockAuthor: null,
            pageUrl: null
        };
    }

    getThumbnail(resource: FormMediaResource) {
        return (
            resource.media_file.preview_image ||
            resource.media_file.thumbnail_filename ||
            resource.media_file.relative_filename
        );
    }

    getFullResolution(resource: FormMediaResource) {
        return (
            resource.media_file.original_url ||
            resource.media_file.relative_filename ||
            resource.media_file.thumbnail_filename
        );
    }

    addMedia() {
        this.action = 'Add';

        this.openMediaDialog();
    }

    resetDialogAction() {
        this.action = 'Add';

        this.mediaToReplace = null;
    }

    onConfirm(resources: FormMediaResource[]) {
        if (this.validate(resources)) {
            this.$refs.MediaDialog.save();
        }
    }

    onMediaSaved(resources: FormMediaResource[]) {
        if (this.action === 'Add') {
            this.add(resources);
        }

        if (this.action === 'Replace') {
            this.replace(
                resources[0],
                this.mediaToReplace as FormMediaResource
            );
        }

        this.resetDialogAction();

        this.closeMediaDialog();

        this.emitMedia();
    }

    emitMedia() {
        this.$emit('mediaFiles', this.resources);
    }

    onMediaDeleted(ids: number[]) {
        this.delete(ids);
    }

    validate(resources: FormMediaResource[]) {
        if (this.action === 'Add') {
            const total = this.resources.length + resources.length;

            if (total > Number(this.max)) {
                const allowed = Number(this.max) - this.resources.length;
                const allowedTail = allowed > 1 ? 's' : '';
                const selectedTail = resources.length > 1 ? 's' : '';

                this.$store.dispatch(
                    'notification/error',
                    `You have ${resources.length} file${selectedTail} selected. Please select no more than ${allowed} file${allowedTail}.`
                );

                return false;
            }
        }

        return true;
    }
}
</script>

<style lang="scss" scoped>
.media-preview__container::v-deep {
    padding: 0;

    .media-preview__wrapper {
        .media-preview__media {
            height: 11em;
            position: relative;
            cursor: pointer;

            .media-preview-list__actions {
                position: absolute;
                height: 90%;
                width: 93%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0.5em;

                .media-preview-list_actions--top,
                .media-preview-list_actions--bottom {
                    display: flex;
                    flex-direction: row !important;
                    z-index: 1;
                }

                .media-preview-list__featured-btn {
                    opacity: 0.5;

                    &.featured {
                        opacity: 1;
                    }
                }
            }

            .media-preview__media-image {
                height: 100%;
            }
        }

        .media-preview__add-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 10em;
            background-color: $grey-light-darker;
            border: 1px dashed #ccc;
            border-radius: 4px;
            cursor: pointer;
            text-align: center;
        }
    }
}
</style>
