<template>
    <v-container
        class="media-tab-container"
        @dragover.stop.prevent="showUploader = true"
        @dragleave.stop.prevent="showUploader = false"
    >
        <v-row v-if="userHasMedia && !showUploader" class="account-container">
            <v-col cols="12" class="actions-container">
                <user-account-media-actions
                    v-if="$vuetify.breakpoint.smAndUp"
                    :actions="actions"
                    @browse="browse"
                />
            </v-col>

            <v-col class="results-container">
                <v-row class="results-wrapper">
                    <v-col
                        v-for="resource in resources"
                        :key="`media-${resource.media_file_id}`"
                        class="media-wrapper"
                        :class="{
                            selected: isSelected(resource.media_file_id)
                        }"
                        cols="6"
                        sm="3"
                    >
                        <div
                            class="actions"
                            :class="{ mobile: $vuetify.breakpoint.mobile }"
                        >
                            <v-tooltip
                                v-if="!isSelected(resource.media_file_id)"
                                bottom
                            >
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        :ripple="false"
                                        class="action-button action-select"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="select(resource)"
                                    >
                                        <v-icon size="20px">square</v-icon>
                                    </v-btn>
                                </template>
                                <span>Click to select media</span>
                            </v-tooltip>

                            <v-btn
                                v-else
                                icon
                                :ripple="false"
                                class="action-button action-deselect"
                                @click="deselect(resource.media_file_id)"
                            >
                                <v-icon size="20px">check-square</v-icon>
                            </v-btn>
                        </div>

                        <v-img
                            class="media-preview"
                            :src="sourceFor(resource)"
                            :class="{ archived: resource.media_file.archived }"
                            @click="preview(resource)"
                            @error="onImageError(resource)"
                        >
                            <template #placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="secondary"
                                    />
                                </v-row>
                            </template>
                        </v-img>
                    </v-col>

                    <template v-if="fetching">
                        <v-col
                            v-for="index in Array.from(Array(4).keys())"
                            :key="`skeleton-${index}`"
                            cols="6"
                            sm="3"
                            class="media-wrapper"
                        >
                            <v-skeleton-loader
                                elevation="2"
                                type="image"
                                height="100%"
                            />
                        </v-col>
                    </template>
                </v-row>
            </v-col>

            <v-col
                v-if="$vuetify.breakpoint.xs"
                cols="12"
                class="actions-wrapper"
            >
                <user-account-media-actions
                    :actions="actions"
                    @browse="browse"
                />
            </v-col>

            <v-col cols="12" class="media-tab-footer">
                <small class="mb-0 d-none d-sm-block">
                    Drag and drop media from your system on above to upload.
                    Must be GIF, PNG, or JP(E)G file types and less than 5mb.
                </small>

                <v-btn
                    class="media-dialog__confirm-btn px-10"
                    color="primary"
                    large
                    :block="$vuetify.breakpoint.xs"
                    :disabled="disableConfirm"
                    :loading="loading"
                    @click="onConfirm"
                >
                    Confirm
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-show="shouldShowUploader" class="uploader-container mx-n3">
            <v-col
                cols="12"
                class="uploader-wrapper d-flex justify-center align-center"
            >
                <v-progress-circular
                    v-if="fetching"
                    class="progress-indicator"
                    indeterminate
                    size="50"
                    width="5"
                />
                <a-media-uploader
                    v-else
                    ref="uploader"
                    :class="{ 'reduce-height': reduceUploaderHeight }"
                    endpoint="/media/save"
                    :options="mediaOptions"
                    :allow-process="false"
                    :allow-multiple="true"
                    style-item-panel-aspect-ratio="1"
                    :disabled="requesting || uploading || fetching"
                    layout="relax"
                    croppable
                    upload-cropped
                    can-rotate-image
                    can-rotate-selection
                    crop-aspect-ratio-from-image
                    @processfiles="onAllUploadsDone"
                    @warning="onMediaUploadWarning"
                    @addfile="onFileAdd"
                    @removefile="onFileRemove"
                    @error="onMediaUploadError"
                />
            </v-col>
        </v-row>

        <v-row v-show="shouldShowUploader" dense class="mx-n3">
            <v-col class="px-0 pt-3 shrink">
                <v-btn :disabled="uploading || fetching" @click="cancel">
                    Cancel
                </v-btn>
            </v-col>

            <v-col class="px-0 pt-3 justify-center align-center d-flex grow">
                <label v-if="userHasMedia && !allCropped" class="primary--text">
                    <v-icon small left color="primary">
                        exclamation-circle
                    </v-icon>
                    Please adjust your images by cropping them so they fit our
                    templates
                </label>
            </v-col>

            <v-col class="px-0 pt-3 text-right shrink">
                <v-btn
                    color="primary"
                    :disabled="!allCropped"
                    :loading="uploading"
                    @click="upload"
                >
                    Upload
                </v-btn>
            </v-col>
        </v-row>

        <user-account-media-preview-modal
            v-if="previewMedia"
            :media-resource="previewMedia"
            @update="onMediaUpdate"
            @close="preview(null)"
        />

        <delete-account-media-modal
            :selected="selected"
            @confirm="deleteMedia"
        />
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { FilePondFile } from 'filepond';

import type { MediaFile } from '@/types/MediaFile';
import type {
    FormMediaResource,
    MediaAction,
    MediaFileAction
} from '@/types/Media';
import type { ServerResponse } from '@/types/ServerResponse';

import { AMediaUploader } from '@/components/AForm/Inputs/AMediaUploader';
import UserAccountMediaPreviewModal from './UserAccountMediaPreviewModal.vue';
import DeleteAccountMediaModal from './DeleteAccountMediaModal.vue';
import UserAccountMediaActions from './UserAccountMediaActions.vue';

import { InjectReactive, Watch } from '@/utils/decorators';
import { setMediaResources } from '@/utils/helpers';

const UserAccountMediaProps = Vue.extend({
    name: 'UserAccountMedia',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AMediaUploader,
        UserAccountMediaPreviewModal,
        DeleteAccountMediaModal,
        UserAccountMediaActions
    }
})
export default class UserAccountMedia extends UserAccountMediaProps {
    $refs!: {
        uploader: AMediaUploader;
    };

    @InjectReactive({
        from: 'allowMultipleSelection',
        default() {
            return true;
        }
    })
    allowMultipleSelection!: boolean;

    @InjectReactive({
        from: 'action',
        default() {
            return 'Add';
        }
    })
    action!: MediaAction;

    @InjectReactive({
        from: 'options',
        default() {
            return {};
        }
    })
    options!: {
        announcement_id?: number;
        author_request_id?: number;
    };

    @InjectReactive({
        from: 'user_id',
        default() {
            return 0;
        }
    })
    user_id!: number;

    uploading = false;

    get fetching() {
        return this.pagination.fetching;
    }

    pagination = {
        fetching: false,
        page: 1,
        max: 1
    };

    resources: FormMediaResource[] = [];

    selected: FormMediaResource[] = [];

    @Watch('selected')
    onSelectionChange() {
        this.updateActions();
    }

    showUploader = false;

    reduceUploaderHeight = false;

    allCropped = false;

    cropperOpener: ReturnType<typeof setTimeout> | void = void 0;

    previewMedia: FormMediaResource | null = null;

    actions: MediaFileAction[] = [
        {
            key: 'clear',
            title: 'Clear',
            icon: 'far fa-rectangle-xmark',
            onClick: () => this.reset(),
            disabled: true
        },
        {
            key: 'download',
            title: 'Download',
            icon: 'download',
            onClick: () => this.download(),
            disabled: true
        },
        {
            key: 'delete',
            title: 'Delete',
            icon: 'trash',
            onClick: () => this.confirmDelete(),
            disabled: true
        },
        {
            key: 'archive',
            title: 'Archive',
            icon: 'box-archive',
            onClick: () => this.archive(),
            disabled: true
        },
        {
            key: 'unarchive',
            title: 'Unarchive',
            icon: 'box-open',
            onClick: () => this.archive(true),
            disabled: true
        }
    ];

    @Watch('previewMedia')
    onPreviewMediaChange(media: FormMediaResource) {
        if (media) {
            this.$store.dispatch('modal/open', 'user-account-media-preview');
        }
    }

    @Watch('resources', { immediate: true, deep: true })
    onResourcesChange(resources: FormMediaResource[]) {
        this.$nextTick(() => {
            if (resources.length > 0) {
                this.setupScrollEvent();
            }
        });
    }

    get requesting(): boolean {
        return this.$store.state.loading.show;
    }

    get mediaOptions() {
        if (this.user_id) {
            return {
                user_id: this.user_id
            };
        }

        return {};
    }

    get userHasMedia() {
        return Boolean(this.resources.length);
    }

    get shouldShowUploader() {
        return !this.userHasMedia || this.showUploader;
    }

    get endpoint() {
        return ['/media/load', this.searchParams].filter(Boolean).join('?');
    }

    get searchParams() {
        const searchParams: Record<string, string> = {
            type: 'account',
            page: String(this.pagination.page)
        };

        // always add announcement-id if in route
        if (this.options.announcement_id) {
            searchParams.announcement_id = String(this.options.announcement_id);
        } else if (this.$route.params.announcementId) {
            searchParams.announcement_id = this.$route.params.announcementId;
        }

        if (this.options.author_request_id) {
            searchParams.author_request_id = String(
                this.options.author_request_id
            );
        }

        if (this.user_id) {
            searchParams.user_id = String(this.user_id);
        }

        return new URLSearchParams(searchParams).toString();
    }

    get selectionContainsArchived() {
        return Boolean(
            this.selected.filter(selected => selected.media_file.archived)
                .length
        );
    }

    get selectionHasAllArchived() {
        return Boolean(
            this.selected.filter(selected => !selected.media_file.archived)
                .length
        );
    }

    get disableConfirm() {
        return (
            !this.selected.length ||
            this.selectionContainsArchived ||
            this.requesting
        );
    }

    reset() {
        this.selected = [];
    }

    download() {
        const mediaFileIds = this.selected.map(
            resource => resource.media_file_id
        );

        this.$http
            .post(
                '/media/download',
                { resources: JSON.stringify(mediaFileIds) },
                { responseType: 'blob' }
            )
            .then(({ data }) => {
                if (data.meta && !data.meta.success) {
                    this.$store.dispatch(
                        'notification/error',
                        data.meta.message
                    );

                    return;
                }

                const url = URL.createObjectURL(new Blob([data]));

                const link = document.createElement('a');

                link.style.display = 'none';

                link.href = url;

                link.download = 'ampifire-images.zip';

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);

                URL.revokeObjectURL(url);
            });
    }

    deleteMedia(mediaFileIds: Array<string | number>) {
        this.$http
            .post<
                ServerResponse<{ deletedMediaFileIds: Array<MediaFile['id']> }>
            >('/media/delete', {
                media_file_ids: mediaFileIds
            })
            .then(({ data }) => {
                if (!data.meta.success) {
                    const errors = data.meta.errors
                        .map(error => `<li>${error}</li>`)
                        .join('');

                    this.$store.dispatch(
                        'notification/error',
                        `<ul>${errors}</ul>`
                    );
                } else {
                    this.$store.dispatch(
                        'notification/success',
                        `${
                            mediaFileIds.length > 1 ? 'Files' : 'File'
                        } deleted successfully.`
                    );
                }

                this.removeMediaFromList(data.data.deletedMediaFileIds);

                this.$store.dispatch('modal/close', 'delete-account-media');
            });
    }

    removeMediaFromList(
        mediaFileIds: Array<FormMediaResource['media_file_id']>
    ) {
        mediaFileIds.forEach(mediaFileId => {
            const index = this.getResourceIndex(mediaFileId);

            if (~index) {
                this.resources.splice(index, 1);
            }
        });

        this.reset();

        this.$emit('deleted', mediaFileIds);
    }

    select(resource: FormMediaResource) {
        if (this.action === 'Replace' || !this.allowMultipleSelection) {
            this.selected = [resource];
        } else {
            this.selected.push(resource);
        }
    }

    getResourceIndex(mediaFileId: FormMediaResource['media_file_id']) {
        return this.resources.findIndex(
            resource => resource.media_file_id === mediaFileId
        );
    }

    getSelectedResourceIndex(mediaFileId: FormMediaResource['media_file_id']) {
        return this.selected.findIndex(
            resource => resource.media_file_id === mediaFileId
        );
    }

    deselect(mediaFileId: FormMediaResource['media_file_id']) {
        const index = this.getSelectedResourceIndex(mediaFileId);

        if (~index) {
            this.selected.splice(index, 1);
        }
    }

    isSelected(mediaFileId: FormMediaResource['media_file_id']) {
        return Boolean(~this.getSelectedResourceIndex(mediaFileId));
    }

    sourceFor(resource: FormMediaResource) {
        return resource.media_file.type === 0
            ? resource.media_file.relative_filename
            : resource.media_file.preview_image;
    }

    preview(resource: FormMediaResource | null) {
        this.previewMedia = resource;
    }

    save() {
        this.$emit('saved', this.selected);
    }

    collect() {
        this.$emit('collected', this.selected);
    }

    setupScrollEvent() {
        const scrollEl = document.querySelector(
            '.media-tab-container .results-wrapper'
        );

        scrollEl?.addEventListener('scroll', this.scrollListener);
    }

    scrollListener(e: Event) {
        const target = e.target as HTMLDivElement;
        if (
            target.scrollTop &&
            target.scrollHeight - target.scrollTop - target.clientHeight < 50
        ) {
            if (this.pagination.page < this.pagination.max) {
                this.load(true);
            }

            target.removeEventListener('scroll', this.scrollListener);
        }
    }

    onMediaUpdate(mediaFile: FormMediaResource['media_file']) {
        const index = this.getResourceIndex(mediaFile.id);

        this.resources[index].media_file = { ...mediaFile };
    }

    onAllUploadsDone() {
        // needed to uploader cleanup with internal ref
        setTimeout(() => {
            this.showUploader = false;

            this.reduceUploaderHeight = false;

            this.uploading = false;

            this.load();
        }, 500);
    }

    onMediaUploadWarning(error: { body: string }) {
        //FIXME:
        if (error.body === 'Max files') {
            this.$store.dispatch(
                'notification/error',
                'Please select no more than 10 images.'
            );
        }
    }

    confirmDelete() {
        this.$store.dispatch('modal/open', 'delete-account-media');
    }

    onMediaUploadError(error: { body: string }) {
        if (error.body) {
            this.$store.dispatch('notification/error', error.body);
        }
    }

    onFileAdd() {
        this.reduceUploaderHeight = true;
        this.showUploader = true;

        this.updateAllCropped();

        if (!this.allCropped) {
            this.markFilesToAttention();
        }
    }

    onFileRemove(_e: null | string, file: FilePondFile) {
        this.$refs.uploader.removeData(file.id);

        const pending = this.$refs.uploader.getFiles();

        if (!pending.length) {
            this.showUploader = false;
        }

        this.updateAllCropped();
    }

    updateAllCropped() {
        const files = this.$refs.uploader.getFiles();

        const allFilesValid = files.every(file =>
            this.$refs.uploader.isValidFile(file)
        );
        const allCropped =
            files.length === Object.keys(this.$refs.uploader.cropData).length;

        this.allCropped = allCropped && allFilesValid;
    }

    markFilesToAttention() {
        const files = this.$refs.uploader.getFiles();

        files.forEach(file => {
            const isCropValid = Boolean(
                file.id in this.$refs.uploader.cropData
            );

            this.$refs.uploader.markFileValid(file.id, isCropValid);

            if (!isCropValid) {
                if (this.cropperOpener) {
                    clearTimeout(this.cropperOpener);
                }

                this.cropperOpener = setTimeout(() => {
                    this.$refs.uploader.tryOpenCropper(file.id);
                }, 500);
            }
        });
    }

    browse() {
        this.$refs.uploader.$refs.uploader.$refs.pond.browse();
    }

    async upload() {
        this.uploading = true;

        try {
            await this.$refs.uploader.tryToUpload();
        } catch {
            /* silently */
        }

        this.uploading = false;
    }

    cancel() {
        this.$refs.uploader.reset();
    }

    async load(fetchMore = false) {
        this.setPage(fetchMore);

        try {
            const { data } = await this.$http.get<
                ServerResponse<{ data: MediaFile[]; max: number }>
            >(this.endpoint, { cache: false });
            if (fetchMore) {
                this.resources = [
                    ...this.resources,
                    ...setMediaResources(data.data.data)
                ] as FormMediaResource[];
            } else {
                this.resources = setMediaResources(data.data.data);
            }

            this.pagination.max = data.data.max;
        } catch {
            this.$store.dispatch(
                'notification/error',
                'Unable to fetch media. Please refresh the page and try again.'
            );
        } finally {
            this.pagination.fetching = false;
        }
    }

    setPage(fetchMore = false) {
        this.pagination.fetching = true;

        if (fetchMore) {
            this.pagination.page++;
        } else {
            this.pagination.page = 1;
        }
    }

    mounted() {
        this.load();
    }

    onConfirm() {
        this.$emit('confirm');
    }

    onImageError(invalidResource: FormMediaResource) {
        this.resources = this.resources.filter(
            resource => resource !== invalidResource
        );
    }

    updateActions() {
        this.actions.map(action => {
            if (this.selected.length) {
                if (
                    (action.key === 'archive' &&
                        this.selectionContainsArchived) ||
                    (action.key === 'unarchive' && this.selectionHasAllArchived)
                ) {
                    action.disabled = true;
                } else {
                    action.disabled = false;
                }
            } else {
                action.disabled = true;
            }

            return action;
        });
    }

    archive(unarchive = false) {
        const mediaFileIds = this.selected.map(media => media.media_file_id);

        this.$http
            .post<ServerResponse<{ archivedMedia: MediaFile['id'] }>>(
                '/media/archive',
                { mediaFileIds }
            )
            .then(() => {
                this.load();

                this.reset();

                this.$store.dispatch(
                    'notification/success',
                    `Media ${
                        unarchive ? 'unarchived' : 'archived'
                    } successfully`
                );
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    `Unable to ${
                        unarchive ? 'unarchive' : 'archive'
                    } media. Please try again.`
                );
            });
    }
}
</script>

<style lang="scss" scoped>
.media-tab-container::v-deep {
    height: 100%;
    padding-top: 0;

    .uploader-container {
        height: calc(100% - 47px);
        margin: 0;

        .uploader-wrapper {
            padding: 0;
            border-radius: 0.5em;
            border: 2px dashed #ccc;

            .media-uploader--wrapper {
                .filepond--root {
                    height: 100%;
                    align-self: auto;

                    .filepond--drop-label {
                        height: 100%;
                    }

                    .filepond--list {
                        .filepond--item {
                            width: calc(50% - 0.5em);
                        }
                    }
                }

                &.reduce-height {
                    .filepond--root {
                        .filepond--drop-label {
                            height: unset !important;
                        }
                    }
                }
            }
        }
    }

    .account-container {
        height: 100%;
        align-content: flex-start;

        .results-container {
            height: calc(100% - 6.5em);
            border: 2px dashed $bonjour;
            border-radius: 4px;
            overflow: hidden;
            padding: 20px 20px 0 20px;

            .results-wrapper {
                display: flex;
                align-content: flex-start;
                height: 100%;
                overflow: auto;
                position: relative;

                .media-wrapper {
                    height: 10em;
                    padding-left: 0;
                    padding-top: 0;

                    .actions {
                        position: absolute;
                        z-index: 1;

                        .action-button {
                            .v-icon {
                                background-color: white;
                                border-radius: 2px;
                                width: 16px !important;
                                height: 16px !important;
                            }

                            &.action-select {
                                .v-icon {
                                    svg {
                                        stroke: $concrete-solid !important;
                                        stroke-width: 8px;
                                        filter: drop-shadow(1px 1px 1px black);

                                        path {
                                            fill: white !important;
                                        }
                                    }
                                    border-bottom: solid 1px black;
                                    filter: drop-shadow(1px 1px 1px black);
                                }
                            }

                            &.action-deselect {
                                .v-icon {
                                    svg {
                                        stroke: $doger-blue !important;

                                        path {
                                            fill: $doger-blue !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .media-preview {
                        height: 100%;
                        cursor: pointer;
                        border: $bonjour 2px solid;
                        transition: all 0.2s ease;

                        &.archived {
                            opacity: 0.3;
                        }
                    }

                    &.selected {
                        .media-preview {
                            border-width: 8px;
                            border-color: $faded-blue;
                        }
                    }
                }
            }
        }

        .media-tab-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            padding: 20px 24px;

            small {
                width: 75%;
            }
        }
    }
}

@media (max-width: 600px) {
    .media-tab-container {
        .account-container {
            .results-container {
                height: calc(100% - 10.25em);
            }

            .actions-wrapper {
                width: 100%;
                padding: 0 !important;
                margin-top: 4px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}
</style>
